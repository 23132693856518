.login-button {
  display: flex;
  width: 2.3rem;
  height: 2.3rem;
  align-items: center;
  justify-content: center;
}

.login-button h4 {
  font-size: 0.9rem;
  color: goldenrod;
}

.mat-mdc-menu-item, .mat-icon-no-color {
  color: rgb(214, 119, 4) !important;
}

.mat-mdc-menu-item-text {
  color: goldenrod;
}

.mat-mdc-menu-item-text:hover {
  color: gold;
}

.mat-mdc-menu-item:hover {
  border: goldenrod 1px solid !important;
}

@import "variables";

.success-snacbar {
  --mdc-snackbar-container-color: #54C054;
  --mat-mdc-snack-bar-button-color: white;
  --mdc-snackbar-supporting-text-color: white;
 }


 .error-snacbar {
  --mdc-snackbar-container-color: #EE4F4B;
  --mat-mdc-snack-bar-button-color: white;
  --mdc-snackbar-supporting-text-color: white;
 }

 .warning-snacbar {
  --mdc-snackbar-container-color: #FF794A;
  --mat-mdc-snack-bar-button-color: white;
  --mdc-snackbar-supporting-text-color: white;
 }

@use '@angular/material' as mat;

@include mat.core();

@import "variables";
@import "navbar";
@import "form";
@import "snackbar";
@import "page";

// $dark-primary-text: rgba(black, 0.87);
// $light-primary-text: white;

/* For use in src/lib/core/theming/_palette.scss */
$mat-primary: (
    50 : #e8e7e5,
    100 : #c5c2be,
    200 : #9f9a93,
    300 : #787268,
    400 : #5b5347,
    500 : #3e3527,
    600 : #383023,
    700 : #30281d,
    800 : #282217,
    900 : #1b160e,
    A100 : #ffbe5d,
    A200 : #ffaa2a,
    A400 : #f69400,
    A700 : #dd8500,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$mat-accent: (
    50 : #e6eaec,
    100 : #c1c9cf,
    200 : #98a6af,
    300 : #6e828e,
    400 : #4f6776,
    500 : #304c5e,
    600 : #2b4556,
    700 : #243c4c,
    800 : #1e3342,
    900 : #132431,
    A100 : #71bbff,
    A200 : #3ea2ff,
    A400 : #0b8aff,
    A700 : #007df1,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$warn-palette: (
    50 : #fff1eb,
    100 : #fedccd,
    200 : #fdc5ac,
    300 : #fcae8b,
    400 : #fc9c72,
    500 : #fb8b59,
    600 : #fa8351,
    700 : #fa7848,
    800 : #f96e3e,
    900 : #f85b2e,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffdcd3,
    A700 : #ffc7b9,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



/* Define dark theme */
$primary-dark: mat.define-palette($mat-primary, 300);
$accent-dark: mat.define-palette($mat-accent, A200, A100, A400);
$warn-dark: mat.define-palette($warn-palette);
//$dark-theme: mat.define-dark-theme($primary-dark, $accent-dark, $warn-dark);
$dark-theme: mat.define-dark-theme((
  color: (
    primary:$primary-dark,
    accent:$accent-dark,
    warn:$warn-dark
  )
));
@include mat.all-component-themes($dark-theme);


html,
body {
  height: 100%;
  min-height: 100vh;
}

body {
  margin: 0;
  // font-family: Bellefair, Roboto, "Helvetica Neue", sans-serif;
  font-size: 1rem;
  color: goldenrod !important;
}

// h1,h2, h3, h4, h5, h6 {
//   color: goldenrod;
//   font-family: Bellefair, Roboto, "Helvetica Neue", sans-serif;
// }

* {
  // color: goldenrod;
  font-family: $font-family; //Bellefair, Roboto, "Helvetica Neue", sans-serif;
 }

 .gold-text {
  color: goldenrod;
  font-family: $font-family;
}


/******** Video ********/
/* Style the video: 100% width and height to cover the entire window */
.video-wrapper {
  top: 0;
  display: block;
  height: 35vh;
  width: 100vw;
  position: absolute;
  // margin-top: 30vh;
  margin-right: 0;
  padding-right: 0;
}

#myVideo {
  position: absolute;
  right: 0;
  bottom: 0;
  min-width: 100vw;
  min-height: 100%;
  opacity: 0.2;
}

/* Add some content at the bottom of the video/page */
.content {
  position: absolute;
  // bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

/* Angular Materials */
.mat-mdc-button {
  font-family: $font-family;
  color: goldenrod;
  font-size: 1rem;

  &:hover {
    // opacity: .4;
    // border: solid 1px goldenrod;
    border: solid 1px rgba(255, 215, 0, .4);
    color: gold;
    background-color: rgb(42, 37, 7, .4);
  }
}

